import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Banner from 'components/Banner';

import index_banner from 'assets/images/pd_pattern_grey.png';

class FaqPage extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout pageName="faq">
        <Helmet>
          <title>FAQ</title>
        </Helmet>
        {/* <Banner source={index_banner}></Banner> */}
        <Container>
          <div className="faq_container">
            <h1>Unsere FAQ</h1>
            <h3>Offene Fragen? Hier sind unsere Antworten.</h3>
            {posts.map(({ node }) => {
              return (
                <div
                  key={node.id}
                  id={node.frontmatter.hash}
                  className="faq-entry"
                  dangerouslySetInnerHTML={{ __html: node.html }}
                />
              );
            })}
          </div>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/faq/" } }
      sort: { order: ASC, fields: [frontmatter___issue] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            issue
            hash
          }
        }
      }
    }
  }
`;

export default FaqPage;
